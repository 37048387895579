export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  ShouldUseSideCart: 'specs.stores.ShouldUseSideCart',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  UseChevronForItemOptions: 'specs.stores.UseChevronForItemOptions',
  MakeCartItemNameLink: 'specs.stores.MakeCartItemNameLink',
  CartItemMoreOptionsFont: 'specs.stores.CartItemMoreOptionsFont',
  SendTrackEventWhenCheckoutLoad: 'specs.stores.SendTrackEventWhenCheckoutLoad',
  ErrorMessage: 'specs.stores.AddErrorMessageInCouponWhenTheInputIsEmpty',
  AddSidePaddingToCartSettingsForDesktop: 'specs.stores.AddSidePaddingSettingsOnCartPageForDesktop',
  UsePageUrlOnItemThumbnail: 'specs.stores.UsePageUrlOnItemThumbnail',
  AddSlotToCartPage: 'specs.stores.AddSlotToCartPage',
  NavigateToCartWhenDontShowSideCartOnMobile: 'specs.stores.NavigateToCartWhenDontShowSideCartOnMobile',
  ExpressCheckoutInSideCart: 'specs.stores.ExpressCheckoutInSideCart',
  FixCartAndSideCartNotSynced: 'specs.stores.FixCartAndSideCartNotSynced',
  shouldChangeToBiCatalogV2: 'specs.stores.shouldChangeToBiCatalogV2',
  FixSelectedThumbnailBug: 'specs.stores.FixSelectedThumbnailBug',
  CheckoutButtonVisibilityInCss: 'specs.stores.CheckoutButtonVisibilityInCss',
  GoToCartButtonVisibilityInCss: 'specs.stores.GoToCartButtonVisibilityInCss',
  SeparateSideCartCurrentCartService: 'specs.stores.SeparateSideCartCurrentCartService',
  ShowCssPerBreakpointIndicationsSideCart: 'specs.stores.ShowCssPerBreakpointIndicationsSideCart',
  SideCartBodyVisibilityInCss: 'specs.stores.SideCartBodyVisibilityInCss',
  SideCartFooterTextVisibilityInCss: 'specs.stores.SideCartFooterTextVisibilityInCss',
  CartHeaderVisibilityInCss: 'specs.stores.CartHeaderVisibilityInCss',
  SupportDeliveryViolationOnCart: 'specs.stores.SupportDeliveryViolationOnCart',
  UseAllStylesInCart: 'specs.stores.UseAllStylesInCart',
  UseGetSettingsWithDefaults: 'specs.stores.UseGetSettingsWithDefaults',
  AddFieldsToViewCartPageBI: 'specs.stores.AddFieldsToViewCartPageBI',
} as const;
